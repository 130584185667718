/**
 * Component for all static colors in Mimir.
 */
export const Color = {
  // PRIMARY
  RUSSIAN_VIOLET: "#3D113F",
  BASTILLE: "#272738",
  BLACK_ROCK: "#272838",
  MIDNIGHT_EXPRESS: "#1D2635",
  RAISIN_BLACK: "#282838",
  ORANGE_YELLOW_CRAYOLA: "#FFDA68",
  FERN_GREEN: "#497D39",
  TRUE_BLUE: "#4866AC",
  ULTRA_RED: "#FF708A",
  TEA_GREEN: "#CCF5AC",
  MIDDLE_BLUE_GREEN: "#89DEDB",
  ONAHAU: "#BDE6FD",

  // SECONDARY
  LAVANDER_WEB_HOVER: "#D9E6FF",
  LAVANDER_WEB_LIST: "#D8D8EC",

  // GRAY SCALE
  WHITE: "#FFFFFF",
  GHOST_WHITE: "#FBFBFF",
  CULTURED: "#FAFAFA",
  WHITE_SMOKE: "#F2F2F2",
  DARK_WHITE_SMOKE: "#F7F7F7",
  JET: "#373737",
  GAINSBORO: "#D9D9D9",
  LIGHT_GREY: "#D4D4D4",
  LIGHT_SILVER: "#C4C4C4",
  SILVER: "#BCBCBC",
  GREY: "#898787",
  BATTLESHIP_GREY: "#8C8C8C",
  DIM_GREY: "#6F6F6F",
  DAVYS_GREY: "#4F4F4F",
  BLACK: "#000000",

  // DARK MODE
  DARKMODE_MAIN: "#888",
  DARKMODE_MODULE: "#A8A8A8",

  // FUNCTION
  LEMON_YELLOW: "#FEF445",
  LEMON_YELLOW_CRAYOLA: "#FFFAA9",
  JASMINE: "#FFDE7A",
  SUNGLOW: "#FBC913",

  // PRODUCT
  ELECTRIC_BLUE: "#00F0FF",
  CELESTE: "#B9F5F9",
  DARK_TURQUOISE: "#47DDE6",
  VIRIDIAN_GREEN: "#069098",

  // LOCATION
  MAGENTA: "#FA00FF",
  PINK_LACE: "#FDCCFE",
  PINK: "#F083F1",
  PURPLE_MUNSELL: "#A300A7",

  // TAG
  BRICK_BROWN: "#8B4B00",
  SAND_BROWN: "#EAC498",
  DIRT_BROWN: "#562E00",

  // ENTITY
  MYSTIC: "#D9EAEB",
  CADET_BLUE: "#65A8AD",
  LAVENDER: "#ECEDFD",
  CORNFLOWER_BLUE: "#8088FF",
  PALE_PURPLE_PANTONE: "#F7DEF8",
  FRENCH_MAUVE: "#D06BD4",
  LINEN: "#FFEEE1",
  CHOCOLATE: "#DF6A10",
  NYANZA: "#DFF1CC",
  ASPARAGUS: "#7A9F53",
  CREAM: "#FCF7C7",
  GOLDENROD: "#E3A814",
  WISTFUL: "#A5A5DC",
};
