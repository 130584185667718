/**
 * Component for static size types in Mimir.
 */
export const Size = {
  NODE_WIDTH: 160,
  NODE_HEIGHT: 95,

  BLOCK_NODE_WIDTH: 2430,
  BLOCK_NODE_HEIGHT: 1100,

  BLOCK_NODE_MIN_WIDTH: 2000,
  BLOCK_NODE_MIN_HEIGHT: 900,

  BLOCK_NODE_MAX_WIDTH: 3300,
  BLOCK_NODE_MAX_HEIGHT: 2000,

  BLOCK_MARGIN_Y: 70,
  BLOCK_MARGIN_X: 45,

  SPLITVIEW_DISTANCE: 120,
  SPLITVIEW_DIVISOR: 1.2,

  // Zoom
  ZOOM_DEFAULT: 1,
  ZOOM_SPLITVIEW: 0.5,

  // Modules
  MODULE_OPEN: 400,
  MODULE_CLOSED: 45,

  // Menus
  TOPMENU_HEIGHT: 54,
};
